/***** Variables *****/
	$darkturqoise: #00656f;
	$turqoise: #a1c5ca;
	$beige: #d8d2c3;
	$lime: #c8c72f;
	$grey: #808080;
	$black: #000;
	$white: #fff;
	
	$nearly-white: #F8F8F8;
	$leegpng: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQI12NgYAAAAAMAASDVlMcAAAAASUVORK5CYII=);

/***** Widths *****/
	@for $i from 1 through 12 {
		$value: $i;
		.col#{$value}-12 {
			width: percentage($value/12) !important;
		}
	}
	.colbreak {
		width: 100%;
		clear: both;
		padding: 0px !important;
	}

	@media all and (max-width: 1023px) {
		// in 3's
		.col1-12, .col2-12, .col3-12, .col4-12 {
			width: percentage(1/3) !important;
		}
		.col5-12, .col6-12, .col7-12 {
			width: percentage(1/2) !important;
		}
		.col8-12, .col9-12 {
			width: percentage(2/3) !important;
		}
		.col10-12, .col11-12, .col12-12 {
			width: 100% !important;
		}

		.blockstyle-margin {
			display: none !important;
		}
	}

	@media all and (max-width: 768px) {
		// in 2's
		.col1-12, .col2-12, .col3-12, .col4-12, .col5-12, .col6-12, .col7-12 {
			width: percentage(1/2) !important;
		}
		.col8-12, .col9-12, .col10-12, .col11-12, .col12-12 {
			width: 100% !important;
		}
	}

	@media all and (max-width: 450px) {
		// in 1's
		.block-content {
			width: 100% !important;
		}
	}

/***** Blocks *****/
	.block-content {
		float: left;
		display: inline-block;
		width: 100%;
		padding: 25px;
		font-family: 'Open Sans', sans-serif;
		font-size: 18px;
		line-height: 1.5;
		color: #555;

		h1, h2, h3, h4, h5, h6 {
			color: $darkturqoise;
			font-family: 'Fira Sans', sans-serif;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
		h1 {
			font-size: 2.8em;
		}
		h2 {
			font-size: 2em;
		}
		h3 {
			font-size: 1.6em;
		}

		a {
			text-decoration: none;
			color: $turqoise;
			font-weight: bold;

			&:hover, &:focus {
				// color: $a_color_over;
				text-decoration: underline;
			}
		}
	}
	.blockstyle-transparent {
		background: transparent;
		// color: $grey;
		h1, h2, h3, h4, h5, h6 {
			// color: $darkturqoise;
		}
		a {
			// color: $turqoise;
		}
	}
	.blockstyle-darkturqoise {
		background: $darkturqoise;
		color: rgba($white, 0.8);
		h1, h2, h3, h4, h5, h6 {
			color: $white;
		}
		a {
			color: $white;
		}
	}
	.blockstyle-turqoise {
		background: $turqoise;
		color: $black;
		h1, h2, h3, h4, h5, h6 {
			color: $darkturqoise;
		}
		a {
			color: $darkturqoise;
		}
	}
	.blockstyle-beige {
		background: $beige;
		color: $black;
		h1, h2, h3, h4, h5, h6 {
			color: $darkturqoise;
		}
		a {
			color: $darkturqoise;
		}
	}
	.blockstyle-lime {
		background: $lime;
		color: rgba($white, 0.8);
		h1, h2, h3, h4, h5, h6 {
			color: $white;
		}
		a {
			color: $white;
		}
	}

/***** Style elements *****/
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.centered {
		display: block !important;
		margin: 0px auto !important;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

