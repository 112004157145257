/***** Custom reset *****/
	*, *:before, *:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		//height: 100%;
		//min-height: 100%;
	}


/***** Imports *****/
	@import 'fonts';
	@import 'general';


/***** Basic styling *****/
	$top-height: 75px;
	$logo-height: 148px;
	$logo-width: ceil( $logo-height * (430 / 423) );

	$logo-small-height: 55px;
	$logo-small-width: ceil( $logo-small-height * (430 / 423) );

	body {
		position: relative;
		background: $white;
		color: $grey;
		overflow-y: scroll;
	}

	.wrapper {
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
	}


/***** Logo *****/
	#logo-outer {
		top: 0px;
		left: 0px;
		position: fixed;
		z-index: 12;
		width: 100%;
		display: inline-block;

		#logo-inner {
			#logo {
				display: inline-block;
				position: absolute;
				top: 30px;
				left: 35px;
				transition: all 0.35s ease;
				
				a {
					font-family: 'Fira Sans', sans-serif;
					color: $darkturqoise;
					font-size: 20px;
					text-transform: uppercase;
					text-decoration: none;

					&:hover, &:focus {
						color: $lime;
					}
				}
			}
		}
	}


/***** Menu *****/
	#menu-outer {
		top: 0px;
		right: 0px;
		width: 100%;
		position: fixed;
		z-index: 11;
		display: inline-block;
		background: transparent;
		height: $top-height;
		font-family: 'Fira Sans', sans-serif;

		&:before {
			content: '';
			height: 75px;
			width: 100%;
			top: 0px;
			left: 0px;
			position: absolute;
			background: #fff;
			box-shadow: 0px 0px 2px 0px rgba(0,0,0, 0.1);
			opacity: 1;
			transition: all 0.35s ease;
		}

		#menu-inner {
			padding-left: ($logo-width + 35);
			// padding-right: 35px;

			> a:nth-child(1) {
				display: none;
				> i {
					//
				}
			}

			#menu {
				padding-top: 15px;

				ul, li {
					list-style: none;
					display: inline-block;
				}

				> .menu-item {
					width: 100%;

					> .menu {
						width: 100%;
						text-align: right;
						padding-right: 15px;

						> .menu-item {
							position: relative;

							&.on > a {
								color: $darkturqoise;

								@media all and (max-width: 1023px) {
									&:before {
										content: '';
										position: absolute;
										left: 0px;
										top: 0px;
										bottom: 0px;
										width: 30px;
										border-left: 30px solid rgba($darkturqoise, 0.1);
										border-top: 40px solid transparent;
										border-bottom: 40px solid transparent;
									}
								}
							}

							> a {
								display: inline-block;
								text-decoration: none;
								text-transform: uppercase;
								color: $lime;
								font-family: 'Fira Sans', sans-serif;
								font-size: 1.4em;
								font-weight: normal;
								padding: 15px 15px;
								height: $top-height - 15px;
								transition: color 0.15s ease;
								
								&:hover {
									color: $darkturqoise;
								}
							}

							> .menu {
								position: absolute;
								top: 100%;
								right: 0px;
								display: none;
								background: rgba($turqoise, 0.8);
								box-shadow: 0px 1px 10px 0px rgba(0,0,0, 0.5);
								min-width: 100%;

								> .menu-item {
									position: relative;
									z-index: 1;
									min-width: 100%;

									> a {
										text-align: left;
										width: 100%;
										display: inline-block;
										text-decoration: none;
										// text-transform: initial;
										text-transform: uppercase;
										color: $darkturqoise;
										// font-weight: 500;
										font-size: 20px;
										font-size: 1.4em;
										padding: 15px 15px;
										height: ($top-height - 10);
										transition: color 0.15s ease;
										white-space: nowrap;
										
										&:hover {
											background: $darkturqoise;
											color: #fff;
										}
									}

									&.on > a {
										color: $darkturqoise;
										
										&:hover, &:focus {
											color: #fff !important;
										}

										@media all and (max-width: 1023px) {
											&:before {
												content: '';
												position: absolute;
												left: 0px;
												top: 0px;
												bottom: 0px;
												width: 30px;
												border-left: 20px solid rgba($darkturqoise, 0.1);
												border-top: 32px solid transparent;
												border-bottom: 32px solid transparent;
											}
										}
									}
								}
							}

							&:hover > .menu {
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}


/***** Content *****/
	.page {
		position: relative;
		display: block;
		width: 100%;
		max-width: 1200px;
		margin: 0px auto;
		padding-top: 15px;
		// border-radius: 0px 0px 10px 10px;
		height: auto;
		min-height: 100%;
		background-color: $white;
		// box-shadow: 0px 0px 75px 0px rgba($darkdarkgreen, 0.15),
		// 	0px 0px 2px 0px rgba(0,0,0, 0.1);
		opacity: 1;

		&:after {
			content: '';
			clear: both;
			width: 1px;
			height: 0px;
			display: block;
		}
	}

	#background_picture {
		position: relative;
		z-index: 5;
		width: 100%;
		background-color: $nearly-white;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		// border-bottom: 18px solid $lightlightgreen;
		height: 300px;

		&:before {
			// content: '';
			position: absolute;
			display: inline-block;
			z-index: 1;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%; // farthest-corner
			background: transparent;
			/* Safari 5.1 to 6.0 */
			background: -webkit-radial-gradient(80% 100%, farthest-corner, rgba(#fff, 0) 50%, rgba(#fff,0.8) 100%); 
			/* For Opera 11.6 to 12.0 */
			background: -o-radial-gradient(80% 100%, farthest-corner, rgba(#fff, 0) 50%, rgba(#fff,0.8) 100%);
			/* For Firefox 3.6 to 15 */
			background: -moz-radial-gradient(80% 100%, farthest-corner, rgba(#fff, 0) 50%, rgba(#fff,0.8) 100%);
			/* Standard syntax */
			background: radial-gradient(farthest-corner at 80% 100%, rgba(#fff, 0) 50%, rgba(#fff,0.8) 100%);
			//box-shadow: inset 0px 150px 150px -75px rgba(#fff, 0.8);
		}

		&:after {
			// content: '';
			position: absolute;
			display: inline-block;
			z-index: 2;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgba($darkturqoise, 0.6);
		}

		&.with_picture {
			height: 350px;

			&:after {
				height: 125px;
			}
		}

		> div.wrapper {
			height: 100%;
		}

	    h1 {
			z-index: 3;
			position: absolute;
			bottom: 18px;
			right: 18px;
			color: #fff;
			font-family: 'Fira Sans', sans-serif;
			font-size: 50px;
			font-weight: bold;
			text-transform: uppercase;
			text-shadow: 0px 1px 10px rgba(0,0,0,0.5), 0px 1px 30px rgba(0,0,0,0.3);
	    }
	}

	#footer {
		position: relative;
		display: block;
		width: 100%;
		float: left;
		background-color: rgba(0,0,0, 0.02);
		box-shadow: inset 0 2px 2px -2px rgba(0,0,0,.1);

		> div {
			position: relative;
			display: block;
			width: 100%;
			max-width: 1200px;
			margin: 0px auto;
		
			.logo {
				width: 80px;
			}

			.social-link {
				font-size: 32px;
			}

			.share-head {
				text-align: right;
				font-size: 70%;
				padding-bottom: 10px;
			}
		}
	}

	#vhw {
		width: auto;
		position: fixed;
		bottom: 10px;
		left: 100%;
		margin-left: -28px;
		font-family: sans-serif;
		font-size: 14px;
		transform: translate(0%, 0%);
		height: 24px;
		white-space: nowrap;
		color: rgba(#fff, 0.3);
		text-decoration: none;
		transition: all 0.35s ease;
		padding-right: 5px;

		img {
			margin-right: 5px;
			border: 0px;
			vertical-align: -30%;
			opacity: 0.3;
		}

		&:hover, &:focus {
			//margin-left: 0px;
			transform: translate(-100%, 0%);
			color: rgba($darkturqoise, 0.5);
			// color: $purple;
			
			img {
				opacity: 0.5;
			}
		}
	}


/***** Modules *****/
	#mod_contact {
        a.action-main {
        	position: relative;
        	clear: right;
        	float: right;
        	margin-top: 25px;
			padding: 10px 15px 10px 45px;
			background: $darkturqoise;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			text-decoration: none;
			text-transform: uppercase;
			box-shadow: 0px 1px 5px 0px rgba(0,0,0, 0);
			transition: all 0.15s ease;
			
			> .fa {
				position: absolute;
				left: 15px;
				top: 10px;
				font-size: 20px;
			}

			&:focus, &:hover {
				color: #fff;
				background-color: $turqoise;
				box-shadow: 0px 1px 5px 0px rgba(0,0,0, 0.3);
			}
        }

		#do_mod_contact {
			position: absolute;
			opacity: 0;
			left: -2000px;
		}
	}

	#mod_signup {
		.confirm_terms {
			font-size: 120%;
		}
	}


/***** Status & Form *****/
    .statusbox {
        width: 100%;
        display: inline-block;
        //margin-bottom: 30px;
        //padding: 15px;
        position: relative;
		margin-bottom: 25px;
    	padding: 25px;
    	padding-left: 65px;
    	background-color: #fff;
        border: 0px solid #888;
        color: #888;
	    box-shadow: 0px 1px 4px 0px rgba(0,0,0, 0.2);

		&:before {
			content: '\f05a'; // info
			border-color: inherit;
			color: inherit;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 24px;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 24px;
			position: absolute;
			left: 35px;
			top: 50%;
			transform: translate(-50%, -50%);
		}

        &.help {
            border: 0px solid #3574B2;
            color: #3574B2;
            &:before {
				content: '\f059';
            }
        }
        &.ok {
            border: 0px solid #4CAF50;
            color: #4CAF50;
            &:before {
				content: '\f058';
            }
        }
        &.warning {
            border: 0px solid #FB8C00;
            color: #FB8C00;
            &:before {
				content: '\f06a';
            }
        }
        &.error {
            border: 0px solid #D32F2F;
            color: #D32F2F;
            &:before {
				content: '\f057';
            }
        }
    }


    input, select, textarea, button, .button {
        &:not([class*="cke_"]) {
            display: inline-block;
            padding: 11px 16px;
            font-size: 1.1rem;
            outline: none;
            vertical-align: top;
            color: rgba(0,0,0, 0.54);
        }
        &[type="file"] {
            background: #fff;
        }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            width: 100%;
        }
    }
    textarea {
        &:not([class*="cke_"]) {
           height: 85px;
       }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            border: 0px solid rgba(0,0,0, 0.3);
            box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);

            &:focus {
            	padding: 10px 15px;
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }
    }

    input[type="checkbox"], input[type="radio"] {
        width: 0px;
        height: 0px;
        opacity: 0;
        padding: 0px;
        margin: 0px;

        & + label {
            display: inline-block;
            position: relative;
            min-height: 20px;
            padding-left: 30px;
            font-size: 1.01rem;
            outline: none;
            margin-right: 30px;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0px + 1px;
                left: 0px;
                width: 20px;
                height: 20px;
                background: #fff;
                //border: 1px solid rgba(0,0,0, 0.3);
                border-radius: 3px;
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 3;
                opacity: 0;
                transition: all 0.15s ease 0s;
                top: 2px + 1px;
                left: 7px;
                width: 7px;
                height: 13px;
                border-bottom: 3px solid mix($darkturqoise, $turqoise, 50%);
                border-right: 3px solid mix($darkturqoise, $turqoise, 50%);
                transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
        &[type="radio"] + label {
            &:before, &:after {
                border-radius: 50%;
            }
            &:after {
                top: 5px + 1px;
                left: 5px;
                width: 10px;
                height: 10px;
                border: 0px;
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                background: mix($darkturqoise, $turqoise, 50%);
            }
        }

        &:hover + label {
            //
        }

        &:focus + label {
            &:before {
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }

        &[type="checkbox"]:checked + label:after {
            opacity: 1;
        }

        &[type="radio"]:checked + label:after {
            opacity: 1;
        }
    }

    button, .button, input[type="submit"] {
        &:not([class*="cke_"]) {
            position: relative;
            cursor: pointer;
            background: #F5F5F5;
            color: rgba(0,0,0, 0.54);
            border: 1px solid rgba(0,0,0, 0.1);
            box-shadow: inset 0px 1px 0px 0px rgba(255,255,255, 0.3), 0px 1px 3px -1px rgba(0,0,0, 0.4);
            border-radius: 3px;
            text-decoration: none;
            padding: 12px 15px;
            height: 45px;

            &:hover, &:focus {
                background: #FAFAFA;
            }
            &:active {
                background: #E0E0E0;
                padding-top: 13px;
                padding-bottom: 11px;
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0, 0.1);

                .fa {
                    margin-top: -9px;
                }
            }

            &.ico {
                padding-left: 37px;
            }
            &.yellow {
                background: #FFA726;

                &:hover, &:focus {
                    background: #FFB74D;
                }
                &:active {
                    background: #FB8C00;
                }
            }
            &.light-blue {
                background: #B3E5FC;

                &:hover, &:focus {
                    background: #E1F5FE;
                }
                &:active {
                    background: #4FC3F7;
                }
            }
            &.add {
                background: #4CAF50;

                &:hover, &:focus {
                    background: #66BB6A;
                }
                &:active {
                    background: #388E3C;
                }
            }
            .fa {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                left: 12px;
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
    }

	.input-range {
		position: relative;
		width: 100%;
		max-width: 200px;
		height: 40px;
		display: inline-block;
		
		.bar {
			display: inline-block;
			position: absolute;
			top: 3px;
			height: 8px;
			left: 7px;
			right: 7px;
			background-color: #ddd;
			z-index: 1;
			&:before {
				content: '';
				position: absolute;
				top: 0px;
				left: -4px;
				width: 4px;
				height: 8px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				background: inherit;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: -4px;
				width: 4px;
				height: 8px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				background: inherit;
			}
		}
		
		.bar-selection {
			display: inline-block;
			position: absolute;
			top: 3px;
			height: 8px;
			left: 7px;
			right: 7px;
			background-color: #ddd; // #aaa
			z-index: 2;
			transition: background-color 0.15s ease;
		}
		
		.handle-min, .handle-max {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: #fff;
			box-shadow: 0px 1px 2px 0px rgba(0,0,0, 0.3);
			z-index: 3;
			cursor: w-resize;
			
			&:after {
				content: attr(data-prefix) '' attr(data-val) '' attr(data-affix);
				position: absolute;
				top: 100%;
				left: 50%;
				white-space: nowrap;
				margin-top: 6px;
				font-size: 11px;
				font-weight: 500;
				background-color: #fff;
				color: #bbb;
				padding: 3px 5px 1px;
				transform: translate(-50%, 0%);
				border-radius: 3px;
				//box-shadow: 0px 1px 1px 0px rgba(0,0,0, 0.15);
				box-shadow: 0px 1px 2px 0px rgba(0,0,0, 0.15);
				z-index: -1;
				transition: color 0.15s ease;
			}
			&:before {
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				margin-top: 3px;
				transform: translate(-50%, 0%);
				width: 6px;
				height: 3px;
				border-color: transparent;
				border-style: solid;
				border-width: 3px;
				border-top: 0px;
				border-bottom: 3px solid #fff;
			}

			&:hover:after {
				color: $darkturqoise;
			}
		}
		
		&.active {
			.bar-selection {
				background-color: $darkturqoise;
			}
			.handle-min, .handle-max {
				&:after {
					color: $darkturqoise;
				}
			}
		}
	}

    .form-label {
        display: inline-block;
        width: 100%;
        padding: 10px 0px 5px;
        font-size: 1.1rem;
       
        &.error {
            color: #D32F2F;
        }
    }

    .form-input {
        display: inline-block;
        width: 100%;
        padding: 5px 0px 20px;
    }

	.frmFieldset {
		border: 2px solid $darkturqoise;
		padding: 2px 15px 15px 15px;

		legend {
			padding: 0px 15px;
			color: $darkturqoise;
			font-size: 18px;
			font-weight: bold;
		}

		.frmField:nth-child(1) {
			margin-top: 13px;
		}
	}

	.frmField {
		float: left;
		width: 100%;
		padding: 5px 5px;
		position: relative;

		&.hide {
			display: none;
		}

		&._err {
			border: 0px;

			&:before {
				content: "\f12a";
				z-index: 2;
				position: absolute;
				top: 2px;
				left: -37px;
				width: 28px;
				height: 28px;
				padding: 5px;
				color: #C68A8B;
				background-color: #8d1517;
				text-shadow: -1px -1px 0px rgba(0,0,0, 0.3);
				box-shadow: 0px 1px 1px 0px rgba(0,0,0, 0.3);
				border-radius: 4px;
				border-bottom: 1px solid rgba(0,0,0, 0.3);
				// display: inline-block;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: 16px;
				// line-height: 18px;
				text-align: center;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				*zoom: 1;
				vertical-align: middle;
				text-transform: none;
			}

			&:after {
				content: "";
				position: absolute;
				left: -9px;
				top: 12px;
				border-color: transparent;
				border-style: solid;
				border-width: 4px;
				border-left-color: #8d1517;
			}
		}

		&.w100-under900 {
			@media all and (min-width: 769px) and (max-width: 899px) {
				width: 100% !important;
			}
		}
	}
	.frmLabel {
		position: relative;
		float: left;
		width: 100%;
		padding-top: 2px;

		> i {
			font-size: 14px;
		}

		> sup {
			position: absolute;
			margin-top: -5px;
		}
	}
	.frmSubmit {
		clear: left;
		float: left;
		width: 100%;
		margin-top: 25px;
		text-align: center;

		.cre8-button, .action {
			float: none !important;
			display: inline-block;
			vertical-align: top;
		}
	}
	.grecaptcha-badge {
		opacity: 0;
		pointer-events: none;
	}
	
	.clear-left {
		clear: left;
	}
	.w90 {
		width: 90% !important;
	}
	.w85 {
		width: 85% !important;
	}
	.w80 {
		width: 80% !important;
	}
	.w75 {
		width: 75% !important;
	}
	.w70 {
		width: 70% !important;
	}
	.w67 {
		width: 66.666% !important;
	}
	.w65 {
		width: 65% !important;
	}
	.w60 {
		width: 60% !important;
	}
	.w50 {
		width: 50% !important;
	}
	.w40 {
		width: 40% !important;
	}
	.w35 {
		width: 35% !important;
	}
	.w33 {
		width: 33.333% !important;
	}
	.w30 {
		width: 30% !important;
	}
	.w25 {
		width: 25% !important;
	}
	.w20 {
		width: 20% !important;
	}
	.w15 {
		width: 15% !important;
	}
	.w10 {
		width: 10% !important;
	}
	.center {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	@media all and (max-width: 499px) {
		.frmField {
			width: 100% !important;
		}
	}


/***** Responsiveness *****/
	@media all and (max-width: 1023px) {
		/*
		img {
			max-width: 100%;
			height: auto !important;
		}
		*/

		#logo-outer {
			&:before {
				height: ($logo-small-height + 20px);
				opacity: 1;
			}

			#logo-inner #logo {
				top: 10px;
				height: $logo-small-height;
				width: $logo-small-width;

				&:before, &:after {
					width: 0px;
					height: $logo-small-height;
					//opacity: 0;
				}

				> a {
					display: inline-block;
					padding: 15px 0px;
				}
			}
		}

		#menu-outer {
			position: fixed;
			width: 100%;
			box-shadow: none;

			#menu-inner {
				padding: 0px;

				&:before {
					content: '';
					position: fixed;
					top: $top-height;
					bottom: 0px;
					left: 0px;
					right: 0px;
					background: rgba(0,0,0, 0.5);
					opacity: 0;
					transition: opacity 0.35s ease;
					pointer-events: none;
				}

				$x-size: 35px;
				> a:nth-child(1) {
					position: absolute;
					top: 0px;
					right: 0px;
					// left: 0px;
					display: inline-block;
					width: ($top-height + 10);
					height: $top-height;
					color: $darkturqoise;
					text-decoration: none;
					border-color: $darkturqoise;
					border-width: 0px;

					> i, &:before, &:after {
						position: absolute;
						top: 49%;
						left: (($top-height - $x-size) / 2) + 5;
						width: $x-size;
						height: 3px;
						border-top-width: 3px;
						border-top-color: inherit;
						border-top-style: solid;
						opacity: 1;
						transition: border-top-color 0.15s ease, transform 0.35s ease, width 0.35s ease, left 0.35s ease, top 0.35s ease, opacity 0.35s ease;
						transform-origin: 50% 50%;
					}

					&:before, &:after {
						content: '';
						top: 32%;
					}
					&:after {
						top: 66%;
					}

					&:hover {
						border-color: #fff;
						border-color: $darkturqoise;
					}
				}

				// > a:nth-child(2) {
				// 	// right: 75px;
				// 	left: 75px;

				// 	&:before {
				// 		display: none;
				// 	}
				// }

				> #menu {
					position: fixed;
					top: $top-height;
					bottom: 0px;
				    overflow: auto;
					width: 0%;
					max-width: 350px;
					right: 0px;
					// left: 0px;
					background: $white;
					box-shadow: 0px 1px 10px 0px rgba(0,0,0, 0.5);
					transition: width 0.35s ease;

					.menu-item {
						width: 100%;

						a {
							width: 100%;
							text-align: right !important;
							// text-align: left !important;
							padding: 20px 25px !important;

							&:hover, &:focus {
								background: $darkturqoise;
								color: #fff !important;
							}
						}

						.menu {
							position: relative !important;
							display: inline-block !important;
							opacity: 1 !important;
							width: 100% !important;
							padding-right: 30px;
							// padding-left: 30px;
							box-shadow: none !important;

							.menu-item a {
								font-size: 2em;
								height: auto;
							}
							.menu .menu-item a {
								padding: 15px 25px !important;
							}
						}
					}
					> .menu-item > .menu {
						padding-right: 0px;
						// padding-left: 0px;
					}
				}

				&.open {
					&:before {
						pointer-events: all;
						opacity: 1;
					}

					> a:nth-child(1) {
						> i {
							opacity: 0;
						}
						&:before {
							top: 49%;
							transform: rotate(-45deg);
						}
						&:after {
							top: 49%;
							transform: rotate(45deg);
						}
					}

					> #menu {
						width: 100%;
					}
				}

			}
		}

		.page {
			&:before {
				opacity: 0.1;
				top: 1 * $top-height;
				margin-right: 0px;
				transform: translateX(50%);
			}

			.page-inner {
				width: 50%;
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					width: 100%;
					max-width: 500px;
					float: none;
					display: block;
					margin: 0px auto;
				}
			}
		}
	}

	@media all and (min-width: 500px) and (max-width: 599px) {
		#background_picture {
			height: 230px;

			&.with_picture {
				height: 380px;
				height: 350px;

				&:after {
					height: 75px;
				}
			}

		    h1 {
				bottom: 10px;
				right: 10px;
				font-size: 42px;
		    }
		}
	}

	@media all and (max-width: 499px) {
		#background_picture {
			height: 230px;

			&.with_picture {
				height: 270px;

				&:after {
					height: 60px;
				}
			}

		    h1 {
				bottom: 10px;
				right: 10px;
				font-size: 32px;
		    }
		}
	}
